/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from "lodash";

import template from './creative-tag.html';
import {AdTagReplacers} from "../ad-unit/ad-tag-replacers";
import {CreativeDefaults} from "./creative-defaults";
import {Uuid} from "../../components/util/uuid";

const MODULE_NAME = "creative-tag-component";

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .component('adnCreativeTagComponent', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      type: '@'
    },
    controller: function(LocalNetworkProfile, AdUnit, searchResource, $document, $window) {
      const ctrl = this;

      ctrl.$onInit = function() {

        ctrl.targetId = "adn-" + Uuid.generate().substring(0, 6);
        ctrl.useCookies = true;
        ctrl.type = ctrl.type || 'CREATIVE';
        ctrl.clickTrackingUrl = '';
        ctrl.targeting = ctrl.type === 'ORDER' || ctrl.type === 'LINE_ITEM' || ctrl.type === 'CREATIVE_SET';
        ctrl.showOptions = ['STANDARD_JAVASCRIPT', 'GOOGLE_AD_MANAGER', 'GOOGLE_CAMPAIGN_MANAGER', 'DV360', 'ADFORM', 'XANDR'];
        ctrl.showOption = 'STANDARD_JAVASCRIPT';
        ctrl.optionCode = {};
        _.forEach(ctrl.showOptions, function(opt) {
          ctrl.optionCode[opt] = {};
        });

        const parentId = _.get(ctrl.model, ['lineItemInfo', 'id']) || _.get(ctrl.model, ['lineItem', 'id']) || _.get(ctrl.model, ['creativeSet', 'id']) || _.get(ctrl.model, ['model', 'id']);
        ctrl.lineItemName = _.get(ctrl.model, ['lineItemInfo', 'name']) || _.get(ctrl.model, ['lineItem', 'name']) || _.get(ctrl.model, ['creativeSet', 'name']);
        const creative = ctrl.type !== 'ORDER' && ctrl.type !== 'LINE_ITEM' && ctrl.type !== 'CREATIVE_SET' ? _.get(ctrl.model, ['model']) : null;

        ctrl.dimensions = _.isArray(ctrl.dimensions) ? ctrl.dimensions : [[]];
        ctrl.dimensions[0] = _.isArray(ctrl.dimensions[0]) ? ctrl.dimensions[0] : [];

        ctrl.removeDimensions = function(index) {
          _.pullAt(ctrl.dimensions, index);
          ctrl.eventHook.removeControlHook('dims' + index + '.width');
          ctrl.eventHook.removeControlHook('dims' + index + '.height');
        };
        ctrl.addDimensions = function() {
          ctrl.dimensions = ctrl.dimensions || [];
          ctrl.dimensions.push([]);
        };
        ctrl.eventHook = {};

        ctrl.downloadAs = function(code, title) {
          // Create a Blob with the text content
          const blob = new $window.Blob([code], { type: "text/plain" });

          // Create a temporary anchor element
          const a = $document[0].createElement("a");
          a.href = $window.URL.createObjectURL(blob);

          a.download = _.lowerCase(title).replaceAll(" ", "-") + ".txt";

          // Append to body and trigger click event
          $document[0].body.appendChild(a);
          a.click();

          // Cleanup
          $document[0].body.removeChild(a);
          $window.URL.revokeObjectURL(a.href);
        };

        ctrl.updateThirdPartyCode = function() {
          ctrl.thirdPartyCode = null;
          ctrl.emailCode = null;
          ctrl.networkTagId = LocalNetworkProfile.get('tagId');
          const whitelabelDomain = AdTagReplacers.getNetworkWhitelabelDomain(LocalNetworkProfile.getNetworkId());

          function updateCode(adTagId) {
            const parentType = _.get(ctrl.model, ['creativeSetParent']) || ctrl.type === 'CREATIVE_SET' ? "creativeSetId" : ctrl.type === 'ORDER' ? 'orderId' : "lineItemId";
            ctrl.clickTracker = CreativeDefaults.getClickTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies);
            ctrl.impTracker = CreativeDefaults.getImpTracker(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies);
            ctrl.optionCode.STANDARD_JAVASCRIPT.code = CreativeDefaults.getJs(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.clickTrackingUrl, ctrl.targeting);
            ctrl.optionCode.STANDARD_JAVASCRIPT.code2 = CreativeDefaults.getScriptServe(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, ctrl.clickTrackingUrl, ctrl.targeting);
            ctrl.optionCode.STANDARD_JAVASCRIPT.code2Title = "Direct Script version of Default Adnuntius Tag";
            ctrl.optionCode.STANDARD_JAVASCRIPT.code3 = CreativeDefaults.getThirdParty(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.clickTrackingUrl, ctrl.targeting);
            ctrl.optionCode.STANDARD_JAVASCRIPT.code3Title = "Iframe version of Default Adnuntius Tag";
            ctrl.optionCode.GOOGLE_AD_MANAGER.code = CreativeDefaults.getJsGAM(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.targeting);
            ctrl.optionCode.GOOGLE_AD_MANAGER.code2 = CreativeDefaults.getScriptServeGAM(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.dimensions, ctrl.targeting);
            ctrl.optionCode.GOOGLE_AD_MANAGER.code2Title = "Direct Script Version of Tag for Google Ad Manager";
            ctrl.optionCode.GOOGLE_AD_MANAGER.limited = true;
            ctrl.optionCode.DV360.code = CreativeDefaults.getJsGAM(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.targeting);
            ctrl.optionCode.DV360.limited = true;
            ctrl.optionCode.XANDR.code = CreativeDefaults.getXandr(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.targeting);
            ctrl.optionCode.ADFORM.code = CreativeDefaults.getAdForm(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.targeting);
            ctrl.optionCode.GOOGLE_CAMPAIGN_MANAGER.code = CreativeDefaults.getGoogleCampaignManager(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), ctrl.useCookies, ctrl.targetId, ctrl.dimensions, ctrl.auW, ctrl.auH, ctrl.targeting);
            ctrl.optionCode.GOOGLE_CAMPAIGN_MANAGER.limited = true;
            const destUrl = _.find(_.get(creative, ['cToUrls'], []), function(v) {
              return !!v;
            }) || "";
            ctrl.emailCode = CreativeDefaults.getEmail(parentType, parentId, creative, adTagId, LocalNetworkProfile.get('tagId'), destUrl, ctrl.useCookies);
            if (whitelabelDomain) {
              ctrl.thirdPartyCode = AdTagReplacers.replace(ctrl.thirdPartyCode, whitelabelDomain, ctrl.networkTagId);
              ctrl.clickTracker = AdTagReplacers.replace(ctrl.clickTracker, whitelabelDomain, ctrl.networkTagId);
              ctrl.impTracker = AdTagReplacers.replace(ctrl.impTracker, whitelabelDomain, ctrl.networkTagId);
              ctrl.scriptServeCode = AdTagReplacers.replace(ctrl.scriptServeCode, whitelabelDomain, ctrl.networkTagId);
              ctrl.scriptServeCodeGAM = AdTagReplacers.replace(ctrl.scriptServeCodeGAM, whitelabelDomain, ctrl.networkTagId);
              ctrl.emailCode = AdTagReplacers.replace(ctrl.emailCode, whitelabelDomain, ctrl.networkTagId);
            }
          }

          if (ctrl.adUnit) {
            AdUnit.get({id: ctrl.adUnit.id}, function(au) {
              const adTagId = _.get(au, "tagId");
              updateCode(adTagId);
            });
          } else {
            updateCode(null);
          }
        };
        ctrl.updateThirdPartyCode();

        ctrl.searchAdUnits = function(searchElement) {
          searchResource.query({"q": searchElement.search, "types": "AdUnit"}).then(function(data) {
            ctrl.adUnitResults = data.searchResults;
          });
        };
      };
    }
  });

export default MODULE_NAME;